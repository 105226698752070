const CATEGORIES = {
  CAPOS: 'capos',
  STRINGS: 'strings',
  STRAPS: 'straps',
  PICKS: 'picks',
  TUNERS: 'tuners',
  STRING_WINDER_CUTTER: 'stringWinderCutter',
  KALIMBAS: 'kalimba',
  KIT: 'kit',
};

export default CATEGORIES;